<template>
  <div>
    <UserCard />
  </div>
</template>

<script>
import UserCard from "../user_card/UserCard";
export default {
  name: "TheUserCard",
  components: {
    UserCard
  }
};
</script>
